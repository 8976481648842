.LoginContainer {
  position: absolute;
  width: 500px;
  height: 500px;
  top: 50%;
  left: 50%;
  margin-left: -250px; /* margin is -0.5 * dimension */
  margin-top: -250px;
}

.DigitalScreen {
  height: 100vh;
  background-color: white;
}

.ContentContainer {
  height: 100vh;
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
}

.ContentHeader {
  background-color: white;
  display: flex;
  align-items: center;
}

.ContentHeader h1 {
  flex: 2;
  text-align: right;
  font-size: 3.5vw;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 300;
  margin: 0;
  padding-right: 20px;
  letter-spacing: 1px;
}

.ContentHeader h2 {
  flex: 2;
  font-size: 2vw;
  color: gray;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 400;
  margin-left: 2%;
  margin-top: 1%;
  letter-spacing: 2px;
}

.ContentHeader img {
  align-content: center;
  justify-content: center;
  flex: 2;
}

.ContentBody ul {
  list-style-type: square;
}

.ContentBody img {
  object-fit: cover;
  margin: 0;
  padding: 0;
}

.ContentBody h1 {
  text-align: left;
  font-size: 4vw;
  color: white;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 500;
  margin-top: 5%;
  margin-left: 5%;
  padding-left: 0;
  text-shadow: 0px 2px 10px #000000;
  letter-spacing: 1px;
}

.ContentBody h2 {
  text-align: left;
  font-size: 3vw;
  color: white;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 500;
  margin: 0;
  padding-left: 0;
  text-shadow: 0px 2px 10px #000000;
  letter-spacing: 2px;
}

.ContentBody li {
  text-align: left;
  font-size: 2vw;
  color: white;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 500;
  margin: 0;
  text-shadow: 0px 2px 10px #000000;
  letter-spacing: 2px;
}

.ContentBody p {
  text-align: left;
  font-size: 2vw;
  color: white;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 300;
  margin: 0;
  padding-left: 0;
  text-shadow: 0px 2px 10px #000000;
  letter-spacing: 2px;
}

.ContentFooter {
  background: linear-gradient(to right, #f8882e, #f8ce2e, white);
  display: flex;
}

.ContentFooterOverlay {
  background-color: #efefef;
  opacity: 0.7;
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 0px; /* top bar above weather */
}

.WeatherWidget {
  flex: 1;
  border-right: 1px solid #bfae81;
  border-left: 1px solid #bfae81;
  padding-top: 10px;
}

.TickerTapeContainer {
  border-right: 1px solid #bfae81;
  border-left: 1px solid #bfae81;
}

.IconWidget {
  flex: 1;
  border-right: 1px solid #bfae81;
  border-left: 1px solid #bfae81;
  padding-top: 10px;
}

.TickerTapeItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 2vw;
  color: #434343;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 400;
}

.Error {
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 500;
  font-size: 1em;
}

.WeatherWidget h1 {
  display: inline;
  font-size: 2.3vw;
  color: #434343;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 400;
  margin: 0;
  padding-left: 10px;
  padding-top: 0px;
}

.WeatherWidget h2 {
  font-size: 5vw;
  color: #434343;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 400;
  margin: 0;
  padding-left: 40px;
}

.WeatherWidget img {
  object-fit: cover;
  float: right;
  padding-right: 10px;
  padding-top: 10px;
  margin: 0;
  margin-left: -20px;
}

.SideBar {
  font-size: 1.25vw;
  color: #434343;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 400;
  margin: 0;
  padding-top: 10px;
}

.SideBar h1 {
  font-size: 3vw;
  color: #000000;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: bold;
  margin: 0;
  text-align: center;
  text-shadow: none;
}

.SideBar h2 {
  font-size: 1.75vw;
  color: #000000;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: 100;
  margin: 0;
  text-align: center;
  text-shadow: none;
}

.SideBar h3 {
  font-size: 1.25vw;
  color: #ecaf71;
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: bold;
  margin: 0;
  text-align: center;
  text-shadow: none;
}

.MenuSection {
  font-size: 1.25vh;
  color: #434343;
  font-family: 'Source Sans Pro', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
    sans-serif;
  font-weight: 400;
  margin: 0;

  /* Add work break rules */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.MenuSection h1 {
  font-size: 3vh;
  color: #000000;
  font-family: 'Source Sans Pro', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
    sans-serif;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  text-shadow: none;
}

.MenuSection h2 {
  font-size: 2vh;
  color: #303030;
  font-family: 'Source Sans Pro', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
    sans-serif;
  font-weight: bold;
  margin: 0;
  text-shadow: none;
}

.MenuSection span {
  font-size: 2vh;
  color: #303030;
  font-family: 'Source Sans Pro', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
    sans-serif;
  font-weight: 200;
  margin: 0;
  text-shadow: none;
}
